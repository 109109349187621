import { cn } from "lib/utils";

export function OpenLogo({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 235 80"
      fill="none"
      className={cn("h-8 text-primary", className)}
    >
      <path
        d="M2.41049 54.06H11.0691C12.3432 54.06 13.4796 55.1964 13.4796 56.4705V65.1291C13.4796 66.4032 14.616 67.5396 15.8901 67.5396H38.1699C39.444 67.5396 40.5804 66.4032 40.5804 65.1291V56.4705C40.5804 55.1964 41.7168 54.06 42.9947 54.06H51.6495C52.9236 54.06 54.06 52.9236 54.06 51.6495V15.8901C54.06 14.616 52.9236 13.4796 51.6495 13.4796H42.9947C41.7168 13.4796 40.5804 12.3432 40.5804 11.0653V2.41049C40.5804 1.13637 39.444 0 38.1699 0H15.8901C14.616 0 13.4796 1.13637 13.4796 2.41049V11.0653C13.4796 12.3432 12.3432 13.4796 11.0691 13.4796H2.41049C1.13637 13.4796 0 14.616 0 15.8901V51.6495C0 52.9236 1.13637 54.06 2.41049 54.06ZM13.4796 51.6495V15.8901C13.4796 14.616 14.616 13.4796 15.8901 13.4796H38.1699C39.444 13.4796 40.5804 14.616 40.5804 15.8901V51.6495C40.5804 52.9236 39.444 54.06 38.1699 54.06H15.8901C14.616 54.06 13.4796 52.9236 13.4796 51.6495Z"
        fill="currentColor"
      />
      <path
        d="M54.4788 80L70.1967 5.95591H83.4199L82.0884 12.3533L83.0373 12.5522C85.046 10.004 87.3724 8.09857 90.0162 6.84358C92.6563 5.58094 95.6981 4.9458 99.1378 4.9458C102.646 4.9458 105.673 5.70721 108.214 7.2262C110.762 8.73754 112.713 10.9567 114.06 13.8837C115.414 16.8146 116.095 20.3653 116.095 24.5358C116.095 27.8225 115.713 31.2163 114.948 34.7134C113.233 42.8364 110.065 49.0233 105.443 53.278C100.829 57.5327 94.8908 59.6601 87.6287 59.6601C84.5066 59.6601 81.8435 59.0517 79.6397 57.8388C77.4434 56.6259 75.7255 54.9692 74.482 52.8648L73.4413 53.0637L67.702 80H54.4788ZM86.6798 48.4264C94.4431 48.4264 99.3751 43.5251 101.479 33.7186C102.019 31.0671 102.291 28.802 102.291 26.9233C102.291 23.2923 101.464 20.5948 99.8112 18.8272C98.1583 17.0633 95.8167 16.1794 92.7864 16.1794C89.1821 16.1794 86.0676 17.4459 83.4352 19.975C80.8105 22.5079 78.9892 26.1428 77.9715 30.8872C77.4587 33.2442 77.2062 35.3983 77.2062 37.3458C77.2062 41.0419 78.0671 43.8121 79.7927 45.6563C81.5145 47.5043 83.8102 48.4264 86.6798 48.4264Z"
        fill="currentColor"
      />
      <path
        d="M173.71 21.8575C172.505 27.7268 169.322 31.9356 164.16 34.4839C158.995 37.0244 152.689 38.2947 145.243 38.2947C142.6 38.2947 140.147 38.1838 137.882 37.958C138.012 41.4704 138.938 44.0914 140.652 45.8246C142.374 47.5617 144.8 48.4264 147.922 48.4264C150.818 48.4264 153.221 47.8563 155.13 46.7123C157.036 45.5606 158.788 43.8044 160.38 41.4475H161.39L169.899 47.2327C168.266 49.8459 166.444 52.046 164.436 53.829C162.434 55.6158 159.898 57.0353 156.829 58.0837C153.757 59.1321 149.992 59.6601 145.534 59.6601C141.482 59.6601 137.87 58.7915 134.699 57.0583C131.534 55.3135 129.051 52.7959 127.245 49.4978C125.447 46.2034 124.552 42.2663 124.552 37.6825C124.552 35.0731 124.858 32.3182 125.47 29.418C126.549 24.3177 128.546 19.9291 131.454 16.256C134.369 12.5752 137.89 9.76678 142.014 7.83839C146.135 5.91 150.554 4.9458 155.268 4.9458C159.286 4.9458 162.706 5.56564 165.522 6.79767C168.338 8.02204 170.458 9.69791 171.889 11.8176C173.328 13.9297 174.047 16.2789 174.047 18.8578C174.047 19.9406 173.932 20.9392 173.71 21.8575ZM154.028 15.2152C151.997 15.2152 150.019 15.682 148.09 16.608C146.162 17.5377 144.417 18.9343 142.856 20.8015C141.295 22.661 140.101 24.972 139.275 27.7345C141.723 27.9296 144.05 28.0253 146.254 28.0253C150.577 28.0253 154.021 27.4858 156.584 26.403C159.155 25.324 160.724 23.4109 161.298 20.6637C161.359 20.2888 161.39 19.9865 161.39 19.7607C161.39 18.4254 160.751 17.3349 159.477 16.4855C158.199 15.64 156.385 15.2152 154.028 15.2152Z"
        fill="currentColor"
      />
      <path
        d="M179.399 58.65L190.587 5.95591H203.289L201.667 13.7001L202.57 13.9297C204.548 10.972 206.894 8.73754 209.61 7.2262C212.334 5.70721 215.625 4.9458 219.482 4.9458C224.326 4.9458 228.014 6.262 230.547 8.89441C233.076 11.5192 234.343 15.154 234.343 19.8067C234.343 21.7886 234.102 23.9542 233.623 26.3111L226.752 58.65H213.513L220.446 26.0204C220.66 25.0332 220.767 24.0269 220.767 23.0053C220.767 20.8129 220.128 19.1179 218.854 17.9242C217.588 16.7304 215.709 16.1335 213.222 16.1335C209.557 16.1335 206.45 17.3656 203.902 19.822C201.361 22.2707 199.528 26.1237 198.407 31.377L192.637 58.65H179.399Z"
        fill="currentColor"
      />
    </svg>
  );
}
